// Modules
import PropTypes from 'prop-types'
import React from 'react'
import {
  graphql,
  Link,
} from 'gatsby'

// Components
import AllPosts from 'components/AllPosts'
import Container from 'components/global-components/Container'
import FeaturedPosts from 'components/FeaturedPosts'
import Hero from 'components/global-components/Hero'
import Layout from 'components/global-components/Layout'
import MetaData from 'components/global-components/MetaData'
import ReactMarkdown from 'react-markdown'

// PropTypes
const propTypes = {
  data: PropTypes.shape({
    markdownRemark: {
      frontmatter: {
        heroData: PropTypes.objectOf(PropTypes.any).isRequired,
        introSection: {
          content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
          header: PropTypes.string,
          tagLink: PropTypes.string.isRequired,
        },
        metadata: PropTypes.objectOf(PropTypes.any).isRequired,
        pageElements: {
          featuredHeader: PropTypes.string.isRequired,
          previousHeader: PropTypes.string.isRequired,
        },
      },
    },
  }),
}

const BlogIndex = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        heroData,
        introSection: {
          tagLink,
          header,
          content,
        },
        metadata,
        pageElements: {
          featuredHeader,
          previousHeader,
        },
      },
    },
  } = data

  const newMetaData = {
    ...metadata,
    robots: {
      follow: metadata.follow,
      index: metadata.index,
    },
  }

  return (
    <Layout>
      <MetaData metaData={newMetaData} />
      <div>
        <Hero heroData={heroData} />
        <section className='bottom-section-space'>
          <Container>
            {
              (header || content) && (
                <>
                  {header && <h2>{header}</h2>}
                  {content && <ReactMarkdown>{content}</ReactMarkdown>}
                </>
              )
            }
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Link
                style={{ textDecoration: 'underline' }}
                to='/blog/tags'
              >
                {tagLink}
              </Link>
            </div>
          </Container>
        </section>
        <section className='section-space'>
          <Container>
            <h2>{featuredHeader}</h2>
            <FeaturedPosts />
          </Container>
        </section>
        <section className='bottom-section-space'>
          <Container>
            <h2>{previousHeader}</h2>
            <AllPosts />
          </Container>
        </section>
      </div>
    </Layout>
  )
}

BlogIndex.propTypes = propTypes
export default BlogIndex
export const pageQuery = graphql`
  query BlogIndexTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "blog-index"}}) {
      frontmatter {
        metadata {
          title
          index
          follow
          description
          ogImage {
            childImageSharp {
              fluid {
                originalImg
                originalName
              }
            }
          }
        }
        heroData {
          isReversed
          header
          content
          imageData {
            imageAlt
            image {
              childImageSharp {
                fluid {
                  originalName
                  originalImg
                }
              }
            }
          }
          curveNumber
          backgroundColor {
            customColor
            selectedColorOption
          }
          fontColor {
            customColor
            selectedColorOption
          }
        }
        introSection {
          tagLink
          header
          content
        }
        pageElements {
          featuredHeader
          previousHeader
        }
      }
    }
  }
`
