// Modules
import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import {
  graphql,
  Link,
  StaticQuery,
} from 'gatsby'

// Scripts
import setImage from 'scripts/setImage'
import {
  largeUp,
  mediumUp,
} from 'scripts/media-query'

const FeaturedPosts = () => {
  const theme = useTheme()
  const FeaturedPostStyles = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: calc(${theme.space.betweenRows} * 2);
    grid-column-gap: calc(${theme.space.betweenColumns} * 2);
    grid-template-columns: 100%;

    ${mediumUp} { grid-template-columns: 1fr 1fr; }
    ${largeUp} { grid-template-columns: 1fr 1fr 1fr 1fr; }

    .featured-post-wrapper  a {
      display: flex;
      height: 100%;
      border-radius: 5px;
    }

    .featured-post {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .image-wrapper {
      height: 45vw;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;

      ${mediumUp} { height: 25vw; }
      ${largeUp} {
        height: 15vw;
        max-height: 200px;
      }
    }

    .post-details {
      flex-grow: 1;
      padding: ${theme.space.all};
      background-color: ${theme.color.grey};
      color: ${theme.color.white};
      text-align: center;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .date {
      width: 80%;
      margin: 0 auto 1rem;
      font-size: .75rem;
      border-bottom: 1px solid;
    }

    .title {
      margin: 0;
      font-family: ${theme.font.cardo};
      font-size: 1.25rem;
    }
  `

  return (
    <FeaturedPostStyles>
      <StaticQuery
        query={
          graphql`
            query FeaturedPostQuery {
              allMarkdownRemark(filter: {frontmatter: {postData: {featuredpost: {eq: true}}, templateKey: {eq: "blog-post"}}}, limit: 4, sort: {fields: frontmatter___date, order: DESC}) {
                edges {
                  node {
                    fields {
                      slug
                    }
                    frontmatter {
                      postData {
                        postImageData {
                          image {
                            childImageSharp {
                              fluid {
                                originalImg
                                originalName
                              }
                            }
                          }
                        }
                      }
                      title
                      date(formatString: "MMMM DD, YYYY")
                    }
                  }
                }
              }
            } 
          `
        }
        render={data => {
          const { allMarkdownRemark: { edges: featuredPosts }} = data

          return (
            <>
              {
                featuredPosts.map((featuredPost, index) => {
                  const {
                    node: {
                      fields: { slug },
                      frontmatter: {
                        date,
                        postData: { postImageData: { image }},
                        title,
                      },
                    },
                  } = featuredPost

                  const img = setImage(image)

                  return (
                    <div
                      className='featured-post-wrapper'
                      key={`featured-post-${index}`}
                    >
                      <Link to={slug}>
                        <div className='featured-post'>
                          <div
                            className='image-wrapper'
                            style={{ backgroundImage: `url(${img})` }}
                          />
                          <div className='post-details'>
                            <p className='date'>{date}</p>
                            <p className='title'>{title}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })
              }
            </>
          )
        }}
      />
    </FeaturedPostStyles>
  )
}

export default FeaturedPosts
