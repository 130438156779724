// Modules
import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import {
  graphql,
  Link,
  StaticQuery,
} from 'gatsby'

// Scripts
import {
  largeUp,
  mediumUp,
} from 'scripts/media-query'

const AllPosts = () => {
  const theme = useTheme()
  const AllPostStyles = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: calc(${theme.space.betweenRows} * 2);
    grid-column-gap: calc(${theme.space.betweenColumns} * 2);
    grid-template-columns: 100%;

    ${mediumUp} { grid-template-columns: 1fr 1fr; }
    ${largeUp} { grid-template-columns: 1fr 1fr 1fr 1fr; }

    .post-wrapper a {
      display: flex;
      height: 100%;
    }

    .post {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: ${theme.space.all};
      background-color: ${theme.color.lightGrey};
      color: ${theme.color.white};
      border-radius: 5px;
    }
    
    .date,
    .title {
      margin: 0;
    }
    
    .date {
      margin-bottom: 1rem;
      font-size: .75rem;
      border-bottom: 1px solid;
    }
    
    .title {
      font-family: ${theme.font.cardo};
      font-size: 1.25rem;
      text-align: center;
    }
  `

  return (
    <AllPostStyles>
      <StaticQuery
        query={
          graphql`
            query AllPostQuery {
              allMarkdownRemark(filter: {frontmatter: {postData: {featuredpost: {eq: false}}, templateKey: {eq: "blog-post"}}}, sort: {fields: frontmatter___date, order: DESC}, limit: 1000) {
                edges {
                  node {
                    fields {
                      slug
                    }
                    frontmatter {
                      title
                      date(formatString: "MM/DD/YYYY")
                    }
                  }
                }
              }
            } 
          `
        }
        render={data => {
          const { allMarkdownRemark: { edges: posts }} = data

          return (
            <>
              {
                posts.map((post, index) => {
                  const {
                    node: {
                      fields: { slug },
                      frontmatter: {
                        date,
                        title,
                      },
                    },
                  } = post

                  return (
                    <div
                      className='post-wrapper'
                      key={`post-${index}`}
                    >
                      <Link to={slug}>
                        <div className='post'>
                          <p className='date'>{date}</p>
                          <p className='title'>{title}</p>
                        </div>
                      </Link>
                    </div>
                  )
                })
              }
            </>
          )
        }}
      />
    </AllPostStyles>
  )
}

export default AllPosts
